
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import pagination from 'vue-bootstrap-pagination'
import {loadPaginatedBiboActivationCodes}  from "@/api/bibo";
import RegisterEndorserModalBibo from "@/views/auth/RegisterEndorserModalBibo2.vue"

export default defineComponent({
  name: "biboactivations",
  components: {
    RegisterEndorserModalBibo
  },
  data() {
    return { 
      list : [],
      bibo_codeid : 0,
      pagination: {
        total: 0,
        per_page: 10,    // required
        current_page: 1, // required
        last_page: 0,    // required
        from: 1,
        to: 12
      }
    }
  },
  mounted(){
      this.getBiboActivationCodes();
  },
  methods : {
    async getBiboActivationCodes(){ 
      const options = {
        params: {
          paginate: this.pagination.per_page,
          page: this.pagination.current_page,
          /* additional parameters */
        }
      };
      const response = await loadPaginatedBiboActivationCodes(1);
      this.list = response.data.pageList;
      this.pagination = response.data.pagination;
      console.log(response.data.pageList);
    },
    async showEndorserRegistrationModal(bibo_codeid){
      this.bibo_codeid = bibo_codeid;
    }
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("biboActivationsTab") || "0");
      setCurrentPageTitle("Bibo Activations");

    });
    
  }
});
